import React, { useState, useRef } from "react"
import styled from "styled-components"
import { colors, headlineThree } from "../Utilities"
import { IoIosAdd } from "react-icons/io"

import WordPressWysiwyg from "./WordPressWysiwyg"

const Item = styled.div`
  margin: 0;
  padding: 0 5rem;
  overflow: hidden;
  border-bottom: 0.1rem dotted rgba(20, 53, 84, 0.25);

  .question {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    background: ${colors.white};
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;

    h3 {
      ${headlineThree};
      margin: 0;
      color: ${colors.colorPrimary};
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: -4rem;
    bottom: 0;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    margin: auto;
    transition: transform 0.6s ease;
    color: ${colors.colorSecondary};

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      transition: transform 0.6s ease;
      transform-origin: center;
    }
  }

  .rotate {
    svg {
      transform: rotate(300deg);
    }
  }

  .answer {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }
`

const FAQItem = ({ question, answer }) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("icon")
  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
    setRotateState(setActive === "active" ? "icon" : "icon rotate")
  }

  return (
    <Item>
      <button className={`question ${setActive}`} onClick={toggleAccordion}>
        <h3>{question}</h3>
        <span className={`${setRotate}`}>
          <IoIosAdd />
        </span>
      </button>
      <div
        className="answer"
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
      >
        <WordPressWysiwyg content={answer} />
      </div>
    </Item>
  )
}

export default FAQItem
