import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import FAQItem from "./FAQItem"
import { standardWrapper, colors } from "../Utilities"

const FAQS = styled.section`
  position: relative;
  margin-top: 15rem;
  padding: 20rem 0 15rem;
  background-color: rgba(157, 201, 228, 0.15);

  .faqImage {
    position: absolute;
    top: -10rem;
    right: 0;
    left: 0;
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border: 1rem solid ${colors.white};

    @media (min-width: 768px) {
      top: -13.5rem;
      width: 27.5rem;
      height: 27.5rem;
      border: 2rem solid ${colors.white};
    }
  }

  .wrapper {
    ${standardWrapper};
    max-width: 900px !important;

    padding: 2rem 0;
    background: ${colors.white};
  }
`

const FAQSection = ({ img, faqs }) => {
  return (
    <FAQS>
      <div className="faqImage">
        <Img fluid={img.localFile.childImageSharp.fluid} alt={img.alt_text} />
      </div>
      <div className="wrapper">
        <div>
          {faqs.map((faq, index) => {
            return (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            )
          })}
        </div>
      </div>
    </FAQS>
  )
}

export default FAQSection
