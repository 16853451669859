import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroImage from "../components/HeroImage"
import Intro from "../components/Intro"
import FAQSection from "../components/FAQSection"

const faq = props => {
  const location = props.location.pathname
  const { acf } = props.data.page

  const metaTitle = acf._swb_theme_meta_title
  const metaDescription = acf._swb_theme_description
  const metaImage = acf._swb_theme_image.localFile.publicURL

  const heroImgFluid = acf._ikag_hero_image.localFile.childImageSharp.fluid

  const introTitle = acf._ikag_intro_title
  const introItalics = acf._ikag_intro_title_italic
  const introContent = acf._ikag_intro_content

  const faqImage = acf._ikag_faq_image
  const faqItems = acf._ikag_faq_faqs

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage fluid={heroImgFluid} />
      <Intro title={introTitle} italic={introItalics} content={introContent} />
      <FAQSection img={faqImage} faqs={faqItems} />
    </Layout>
  )
}

export const homeQuery = graphql`
  query faqPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
        _ikag_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_intro_title
        _ikag_intro_title_italic
        _ikag_intro_content

        _ikag_faq_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_faq_faqs {
          question
          answer
        }
      }
    }
  }
`

export default faq
